*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}
.visible {
  visibility: visible;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.right-0 {
  right: 0px;
}
.right-6 {
  right: 1.5rem;
}
.top-0 {
  top: 0px;
}
.top-6 {
  top: 1.5rem;
}
.z-\[-1\] {
  z-index: -1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.mt-\[18px\] {
  margin-top: 18px;
}
.mt-\[72px\] {
  margin-top: 72px;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.h-10 {
  height: 2.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-6 {
  height: 1.5rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-\[90px\] {
  height: 90px;
}
.min-h-\[400px\] {
  min-height: 400px;
}
.min-h-\[calc\(100vh-180px\)\] {
  min-height: calc(100vh - 180px);
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-3 {
  width: 0.75rem;
}
.w-36 {
  width: 9rem;
}
.w-4 {
  width: 1rem;
}
.w-48 {
  width: 12rem;
}
.w-60 {
  width: 15rem;
}
.w-8 {
  width: 2rem;
}
.w-\[1000px\] {
  width: 1000px;
}
.w-\[100px\] {
  width: 100px;
}
.w-\[1200px\] {
  width: 1200px;
}
.w-\[400px\] {
  width: 400px;
}
.w-\[820px\] {
  width: 820px;
}
.w-full {
  width: 100%;
}
.max-w-\[1260px\] {
  max-width: 1260px;
}
.max-w-\[90\%\] {
  max-width: 90%;
}
.max-w-full {
  max-width: 100%;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.grow {
  flex-grow: 1;
}
.origin-center {
  transform-origin: center;
}
.-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.flex-col {
  flex-direction: column;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-1\.5 {
  gap: 0.375rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-\[18px\] {
  gap: 18px;
}
.gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.gap-y-2 {
  row-gap: 0.5rem;
}
.gap-y-4 {
  row-gap: 1rem;
}
.overflow-hidden {
  overflow: hidden;
}
.break-words {
  overflow-wrap: break-word;
}
.break-all {
  word-break: break-all;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-\[50\%\] {
  border-radius: 50%;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.border {
  border-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-\[\#91D5FF\] {
  --tw-border-opacity: 1;
  border-color: rgb(145 213 255 / var(--tw-border-opacity));
}
.border-\[\#B7EB8F\] {
  --tw-border-opacity: 1;
  border-color: rgb(183 235 143 / var(--tw-border-opacity));
}
.border-\[\#F0F0F0\] {
  --tw-border-opacity: 1;
  border-color: rgb(240 240 240 / var(--tw-border-opacity));
}
.border-\[\#FFA39E\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 163 158 / var(--tw-border-opacity));
}
.border-\[\#FFD591\] {
  --tw-border-opacity: 1;
  border-color: rgb(255 213 145 / var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}
.bg-\[\#1890FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(24 144 255 / var(--tw-bg-opacity));
}
.bg-\[\#52C41A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(82 196 26 / var(--tw-bg-opacity));
}
.bg-\[\#E6F7FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(230 247 255 / var(--tw-bg-opacity));
}
.bg-\[\#F5222D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 34 45 / var(--tw-bg-opacity));
}
.bg-\[\#F6FFED\] {
  --tw-bg-opacity: 1;
  background-color: rgb(246 255 237 / var(--tw-bg-opacity));
}
.bg-\[\#FAAD14\] {
  --tw-bg-opacity: 1;
  background-color: rgb(250 173 20 / var(--tw-bg-opacity));
}
.bg-\[\#FFF1F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 240 / var(--tw-bg-opacity));
}
.bg-\[\#FFF7E6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 247 230 / var(--tw-bg-opacity));
}
.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.bg-cyfs-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(167 170 174 / var(--tw-bg-opacity));
}
.bg-cyfs-green {
  --tw-bg-opacity: 1;
  background-color: rgb(10 215 182 / var(--tw-bg-opacity));
}
.bg-cyfs-green2 {
  --tw-bg-opacity: 1;
  background-color: rgb(25 183 150 / var(--tw-bg-opacity));
}
.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}
.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}
.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}
.p-4 {
  padding: 1rem;
}
.p-6 {
  padding: 1.5rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pb-80 {
  padding-bottom: 20rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pt-20 {
  padding-top: 5rem;
}
.text-center {
  text-align: center;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-\[24px\] {
  font-size: 24px;
}
.text-\[40px\] {
  font-size: 40px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-bold {
  font-weight: 700;
}
.font-medium {
  font-weight: 500;
}
.font-normal {
  font-weight: 400;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-8 {
  line-height: 2rem;
}
.leading-\[60px\] {
  line-height: 60px;
}
.text-\[\#1890FF\] {
  --tw-text-opacity: 1;
  color: rgb(24 144 255 / var(--tw-text-opacity));
}
.text-\[\#52C41A\] {
  --tw-text-opacity: 1;
  color: rgb(82 196 26 / var(--tw-text-opacity));
}
.text-\[\#F5222D\] {
  --tw-text-opacity: 1;
  color: rgb(245 34 45 / var(--tw-text-opacity));
}
.text-\[\#FA8C16\] {
  --tw-text-opacity: 1;
  color: rgb(250 140 22 / var(--tw-text-opacity));
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-black-primary {
  color: #000000E0;
}
.text-black-secondary {
  color: #00000073;
}
.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}
.text-cyfs-gray {
  --tw-text-opacity: 1;
  color: rgb(167 170 174 / var(--tw-text-opacity));
}
.text-cyfs-green {
  --tw-text-opacity: 1;
  color: rgb(10 215 182 / var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}
.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}
.text-red-300 {
  --tw-text-opacity: 1;
  color: rgb(252 165 165 / var(--tw-text-opacity));
}
.text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}
.underline {
  text-decoration-line: underline;
}
.no-underline {
  text-decoration-line: none;
}
.shadow-gray-200 {
  --tw-shadow-color: #e5e7eb;
  --tw-shadow: var(--tw-shadow-colored);
}
.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

* {
  padding: 0;
  margin: 0;
}

body {
  /* background-color: black; */
  color: black;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.title {
  font-size: 3.75rem;
  line-height: 1;
  font-weight: 700;
}

.box {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1200px;
}

.box2 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 920px;
}

.box3 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1020px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-round {
  border-radius: 0.5rem;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(240 240 240 / var(--tw-border-opacity));
}

.btn-dan {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn-dan:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.hover\:bg-cyfs-green2:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(25 183 150 / var(--tw-bg-opacity));
}

.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:text-\[\#0066CC\]:hover {
  --tw-text-opacity: 1;
  color: rgb(0 102 204 / var(--tw-text-opacity));
}

.hover\:text-black-secondary:hover {
  color: #00000073;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

@media (min-width: 768px) {
  .md\:hidden {
    display: none;
  }
  .md\:max-w-\[70\%\] {
    max-width: 70%;
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:items-start {
    align-items: flex-start;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:gap-6 {
    gap: 1.5rem;
  }
  .md\:gap-\[38px\] {
    gap: 38px;
  }
}

@font-face {
font-family: '__font_373da2';
src: url(/_next/static/media/0d095b7d0b04cc89-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__font_Fallback_373da2';src: local("Arial");ascent-override: 98.74%;descent-override: 46.49%;line-gap-override: 0.00%;size-adjust: 112.92%
}.__className_373da2 {font-family: '__font_373da2', '__font_Fallback_373da2'
}

